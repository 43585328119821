export async function loadMapScript() {
  try {
    // Если API уже загружено, возвращаем успешный результат
    if (window.ymaps3) {
      return { status: true, msg: "Api YMap already loaded" };
    }

    // Создаём тег <script>
    const script = document.createElement("script");
    script.src =
      "https://api-maps.yandex.ru/v3/?apikey=c1a9da1f-1491-466c-bd4f-178e263227a3&lang=ru_RU";

    // Оборачиваем загрузку скрипта в Promise
    const result = await new Promise((resolve, reject) => {
      script.onload = () => {
        resolve({ status: true, msg: "Api YMap Load Success" });
      };
      script.onerror = () => {
        reject({ status: false, msg: "Api YMap Load Error" });
      };
      document.head.appendChild(script);
    });

    return result;
  } catch (error) {
    console.error("Ошибка при загрузке Яндекс Карт API:", error);
    return { status: false, msg: "Api YMap Load Error" };
  }
}
