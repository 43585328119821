<template>
  <div>
    <MapView />
    <LoadView />

    <User />

    <!--
    <DebugBlock v-show="this.debugVisible" />
    <button class="toggleDebug" @click="this.debugVisible = !this.debugVisible">
      data info
    </button>-->
    <button class="updatePosition" @click="this.updatePosition">
      updatePosition
    </button>
    <!-- Санкт-Петербург -->
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import getUserPosition from "./services/map/getUserPosition"
import updatePosition from "./services/map/updatePosition"
//import bridge from "@vkontakte/vk-bridge";

//import components
import DebugBlock from "./components/DebugBlock.vue"
import DeviceDetector from "./components/DeviceDetector.vue"
import User from "./components/User.vue"
import VKBridge from "./components/vk/VKBridge.vue"
import LoadView from "./views/LoadView.vue"
import MapView from "./views/MapView.vue"

export default {
  name: "App",
  data() {
    return {
      debugVisible: false,
    };
  },
  components: {
    LoadView,
    DebugBlock,
    MapView,
    DeviceDetector,
    VKBridge,
    User,
  },
  computed: {
    ...mapGetters(["USER","GAME_READY"]),
  },
  methods: {
    updatePosition() {
      getUserPosition().then((geoData) => {
        updatePosition([geoData.longitude, geoData.latitude]);
      });
    },
  },
  watch: {


  }
};
</script>
<style scoped>
.toggleDebug {
  height: 30px;
  position: fixed;
  right: 15px;
  bottom: 40px;
  padding: 2px 10px;
  border-radius: 14px;
  border: 1px solid #24292f;
}
.updatePosition {
  height: 30px;
  position: fixed;
  left: 15px;
  bottom: 40px;
  padding: 2px 10px;
  border-radius: 14px;
  border: 1px solid #24292f;
}
.updatePosition:hover {
  cursor: pointer;
}
</style>
