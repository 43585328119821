<template>
  <div>
    <!-- Ваш шаблон -->
    <History></History>
  </div>
</template>

<script>
import { checkUser } from "@/services/user/checkUser";
import { mapGetters, mapMutations } from "vuex";

import History from "@/components/history/History.vue";

export default {
  name: "User",
  components: {
    History,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["GAME_READY", "USER"]),
  },
  methods: {
    ...mapMutations(["SET_HISTORY_VISIBLE_NUM"]),
  },
  watch: {
    GAME_READY(newVal, oldVal) {
      if (newVal) {
        console.log("поехали играться с пользователем!!", this.USER.vk_id);

        checkUser(this.USER.vk_id)
          .then((data) => {
            console.log("сколько таких на сервере:", data);
            //если что то пришло
            if (data.result) {
              if (data.count == 0) {
                console.log("показываем первую историю");
                // показываем историю и предлагаем выбор после чего кладем
                this.SET_HISTORY_VISIBLE_NUM(1);
              } else {
                // цепляем текущие данные
              }
            }
            // Делаем что-то с данными
          })
          .catch((error) => {
            console.error("Ошибка:", error);
          });
      }
    },
  },
};
</script>
