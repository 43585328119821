import turf from "@/assets/libs/turf";

export default  function drawUser(userLocation){
  console.log('рисуем пользователя')
  const { YMapMarker, YMapFeature } = ymaps3;
  const userMarker = document.createElement("div");
  userMarker.className = "player";
  window.userPoint = new YMapMarker(
    {
      coordinates: userLocation,
      position: "top-center left-center",
      draggable: false,
      mapFollowsOnDrag: true,
    },
    userMarker
  );
  window.gameMap.addChild(userPoint);

  let userFieldPoints = turf.circle(userLocation, 25, {
    units: "meters",
    steps: 100,
  });
  //console.log(userFieldPoints.geometry);
   window.userField = new YMapFeature({
    geometry: userFieldPoints.geometry,
    style: {
      stroke: [{ width: 1, color: "rgb(256 256 256 / 80%" }],
      fill: "rgb(256 256 256 / 30%)",
    },
  });
  window.gameMap.addChild(userField);
}
