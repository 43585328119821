<template>
  <div>
    <div
      ref="mapContainer"
      id="mapContainer"
      style="width: 100%; height: 100vh; background-color: black"
    ></div>
  </div>
</template>

<script>
export default {
  name: "MapView",
  data() {},
};
</script>

<style>
.player {
  width: 5px;
  height: 5px;
  margin-left: -5px;
  margin-top: -5px;
  border-radius: 50%;
  border: 3px solid white;
}
.building {
  width: 20px;
  height: 20px;
  margin-left: -10px;
  margin-top: -10px;
}
.buildingImg {
  max-width: 20px;
  max-height: 20px;
}
</style>
