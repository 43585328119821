<template>
  <History_1 v-if="history_1"></History_1>
</template>
<script>
import History_1 from "@/components/history/History1.vue";
import { mapGetters } from "vuex";
export default {
  name: "History",
  data() {
    return {
      history_1: false,
    };
  },
  computed: {
    ...mapGetters(["HISTORY_VISIBLE_NUM"]),
  },
  components: {
    History_1,
  },
  watch: {
    HISTORY_VISIBLE_NUM(newNum, oldVal) {
      if (newNum == 1) {
        this.history_1 = true;
      } else {
        this.history_1 = false;
      }
    },
  },
};
</script>
