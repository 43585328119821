const history = {
  state: {
    hisoryVisibleNum:0
  },
  mutations: {
    SET_HISTORY_VISIBLE_NUM: (state, num) => {
      state.hisoryVisibleNum = num;
    },

  },
  getters: {
    HISTORY_VISIBLE_NUM: (state) => {
      return state.hisoryVisibleNum;
    },
  },
  actions: {},
};
export default history;
