
import updateMap from "@/services/map/updateMap";
import updateUserPoint from "./updateUserPoint";

export default function updatePosition(coords) {
        // Местоположение получено
        updateMap(coords);
        updateUserPoint(coords);
        console.log(coords);
}
