export default function getUserPosition() {
  console.log("вызвали гет коордс");

  const options = {
    enableHighAccuracy: true,
    maximumAge: 1000,
    // timeout: 3600,
  };

  // Возвращаем новый Promise
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      console.log("Ваш браузер не дружит с геолокацией...");
      reject(new Error("Геолокация не поддерживается вашим браузером"));
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("из гет коордс");
          console.log(position.coords);
          resolve(position.coords); // Возвращаем координаты
        },
        (error) => {
          console.log("Не получается определить вашу геолокацию :(");
          reject(error); // Возвращаем ошибку
        },
        options
      );
    }
  });
}
