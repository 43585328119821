import { createStore } from "vuex"
import gameReady from "./gameReady"
import history from './history'
import mapReady from "./mapReady"
import user from "./user"

export default createStore({
  state: {
    user: {
      id: 0,
      name: "noName",
      avatar: "https://vk.com/images/camera_100.png",
      coords: [0, 0],
      lvl: 0,
    },

    device: {
      type: "неизвестное устройство",
    },
    //строения (маяки/глушилки)
    buildings: [
      {
        id: 1,
        type: 1,
        coords: [37.608878, 55.741407],
        img: require("@/assets/img/markers/mot.gif"),
        strokeColor: "#008ff5",
        fillColor: "rgb(0 145 244 / 30%)",
        radius: 50,
      },
      {
        id: 2,
        type: 1,
        coords: [37.606155, 55.740902],
        img: require("@/assets/img/markers/mot_a.gif"),
        strokeColor: "#008ff5",
        fillColor: "rgb(0 145 244 / 30%)",
        radius: 50,
      },
      {
        id: 3,
        type: 1,
        coords: [37.609991, 55.740466],
        img: require("@/assets/img/markers/mo_b.gif"),
        strokeColor: "#008ff5",
        fillColor: "rgb(0 145 244 / 30%)",
        radius: 50,
      },
      {
        id: 4,
        type: 2,
        coords: [37.611469, 55.742494],
        img: require("@/assets/img/markers/got.gif"),
        strokeColor: "#ff8201",
        fillColor: "rgb(255 122 1 / 30%)",
        radius: 100,
      },
    ],
    map: {
      center: [37.588144, 55.733842],
      zoom: 18,
      userCoord: [37.588144, 55.733842],
    },
    items: {
      battary: {
        energy: 0.8,
      },
    },
    game: {
      ready: false,
    },
  },
  //изменяют стейт
  mutations: {
    SET_DEVICE_TYPE: (state, deviceType) => {
      state.device.type = deviceType;
    },
    SET_GAME_READY: (state, ready) => {
      state.game.ready = ready;
    },
  },
  //ассинхронно что то делают
  actions: {},
  getters: {
    POINTS: (state) => {
      return state.points;
    },
    MAP: (state) => {
      return state.map;
    },
    DEVICE: (state) => {
      return state.device;
    },
    BUILDINGS: (state) => {
      return state.buildings;
    },
    GAME_READY: (state) => {
      return state.game.ready;
    },
  },

  //если надо разделить
  modules: {
    mapReady,
    user,
    gameReady,
    history
  },
});
