import mapStyle from "@/assets/custom_map.json";

export async function initMap(mapContainerId) {
  try {
    // Ожидаем, пока ymaps3 загрузится
    await ymaps3.ready;

    // Если ymaps3 загружен, создаём карту
    const { YMap, YMapDefaultSchemeLayer, YMapDefaultFeaturesLayer } = ymaps3;
    const mapContainer = document.querySelector(mapContainerId);

    if (!mapContainer) {
      throw new Error(`Элемент с id ${mapContainerId} не найден.`);
    }

    // Устанавливаем центр карты на местоположение пользователя
    window.gameMap = new YMap(mapContainer, {
      location: {
        center: [30.313497, 59.938531], // Используем координаты пользователя
        zoom: 12, // Масштаб
      },
    });

    // Добавляем слои карты
    window.gameMap.addChild(
      new YMapDefaultSchemeLayer({
        theme: "dark",
        customization: mapStyle,
      })
    );
    window.gameMap.addChild(new YMapDefaultFeaturesLayer());

    return { status: true, msg: "Map Initialized" };
  } catch (error) {
    console.error("Ошибка при инициализации карты:", error);
    return { status: false, msg: "Map Initialization Error" };
  }
}
