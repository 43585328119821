<template>
  <div class="history_container">
    <!-- Текущее изображение -->
    <img
      :src="getImageUrl(frames[currentIndex].img)"
      :alt="'Frame ' + (currentIndex + 1)"
      class="frame-image"
    />

    <!-- Текст слайда -->
    <div class="frame-text" :class="{ contact: frames[currentIndex].contact }">
      {{ frames[currentIndex].text }}
    </div>

    <!-- Навигационные кнопки -->
    <button
      class="nav-button prev"
      @click="prevFrame"
      :disabled="currentIndex === 0"
    >
      ◀
    </button>

    <button
      class="nav-button next"
      @click="nextFrame"
      :disabled="currentIndex === frames.length - 1"
    >
      ▶
    </button>

    <!-- Индикаторы слайдов -->
    <div class="frame-indicators">
      <span
        v-for="(frame, index) in frames"
        :key="index"
        :class="{ active: index === currentIndex }"
        @click="goToFrame(index)"
      ></span>
    </div>
  </div>
</template>

<script>
import bridge from "@vkontakte/vk-bridge";
export default {
  name: "History_1",
  data() {
    return {
      frames: [
        {
          img: "1.jpg",
          text: "мы все помним, как впервые пришел сигнал из глубокого космоса..",
        },
        {
          img: "2.jpg",
          text: "..спутники, радио, тв, даже детские рации транслировали в течение часа призыв о помощи..",
        },
        {
          img: "3.jpg",
          text: "..потерпели крушение, можете спасти, ответьте, закрутите сигнал..",
        },
        {
          img: "4.jpg",
          text: "ЭТО РАЗДЕЛИЛО НАС",
          contact: false,
        },
        {
          img: "5.jpg",
          text: "ЭКОНОМИЧЕСКИЙ КРАХ НЕИЗБЕЖЕН",
          contact: false,
        },
        {
          img: "6.jpg",
          text: "ЭТО ПОТРЯСАЮЩЕ",
          contact: true,
        },
        {
          img: "7.jpg",
          text: "СОБЛЮДАЙТЕ ПОРЯДОК",
          contact: false,
        },
        {
          img: "8.jpg",
          text: "ПЕРВЫЙ КОНТАКТ: ПРЯМОЙ ЭФИР",
          contact: true,
        },
        {
          img: "9.jpg",
          text: "СИТУАЦИЯ ПОД КОНТРОЛЕМ",
          contact: false,
        },
        {
          img: "10.jpg",
          text: "ЭТО ВОЗМОЖНОСТЬ ДЛЯ ВСЕХ НАС",
          contact: true,
        },
      ],
      currentIndex: 0, // начинаем с первого слайда
      autoPlayInterval: null,
    };
  },

  methods: {
    getImageUrl(imgName) {
      return require(`@/assets/img/history/history1/${imgName}`); // или другой путь к вашим изображениям
    },

    prevFrame() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },

    nextFrame() {
      if (this.currentIndex < this.frames.length - 1) {
        this.currentIndex++;
      }
      this.vibro();
    },

    goToFrame(index) {
      this.currentIndex = index;
    },

    startAutoPlay() {
      this.autoPlayInterval = setInterval(() => {
        if (this.currentIndex < this.frames.length - 1) {
          this.currentIndex++;
        } else {
          this.currentIndex = 0; // возврат к началу
        }
      }, 3000); // 5 секунд между слайдами
    },

    stopAutoPlay() {
      if (this.autoPlayInterval) {
        clearInterval(this.autoPlayInterval);
      }
    },
    vibro() {
      bridge
        .send("VKWebAppTapticImpactOccurred", {
          style: "heavy",
        })
        .then((data) => {
          if (data.result) {
            // Вибрация вызвана
          }
        })
        .catch((error) => {
          // Ошибка
          console.log(error);
        });
    },
  },

  mounted() {
    //this.startAutoPlay(); // запуск автоматической прокрутки
  },

  beforeUnmount() {
    this.stopAutoPlay(); // очистка интервала при удалении компонента
  },
};
</script>

<style scoped>
.history_container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.frame-image {
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.frame-text {
  color: white;
  font-size: 1.2rem;
  text-align: center;
  min-width: 100%;
  padding-bottom: 30px;
  padding-top: 30px;
  background-color: rgba(0, 0, 0, 0.7);
  /* margin-bottom: 20px; */
  position: absolute;
  bottom: 0;
  font-family: monospace;
}

.frame-text.contact {
  background-color: #008ff5;
}

.nav-button {
  position: absolute;
  top: 50%;
  background-color: #869aa57a;
  border: 2px solid #0c1315;
  color: #0c1315;
  border-radius: 50%;
  width: 43px;
  height: 42px;
  font-size: 22px;
  cursor: pointer;
  padding-left: 0px;
}

.nav-button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.nav-button.prev {
  left: 20px;
}

.nav-button.next {
  right: 20px;
}

.frame-indicators {
  position: absolute;
  bottom: 20px;
  display: flex;
  gap: 10px;
}

.frame-indicators span {
  display: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: all 0.3s;
}

.frame-indicators span.active {
  background-color: white;
  transform: scale(1.2);
}

.frame-indicators span:hover {
  background-color: white;
}
</style>
