const user = {
  state: {
    user: {
      id: 0,
      vk_id: 0,
      nickname: "noName",
      avatar: "https://vk.com/images/camera_100.png",
      side: 0,
      coords: [0, 0],
      lvl: 0,
      xp:0,
    },
  },
  mutations: {
    SET_USER_NAME: (state, userName) => {
      state.user.name = userName;
    },
    SET_USER_VK_ID: (state, id) => {
      state.user.vk_id = id;
    },
    SET_USER_VK_AVA: (state, avatar) => {
      state.user.avatar = avatar;
    },
    SET_USER_COORDS: (state, coords) => {
      state.user.coords = coords;
    },
  },
  getters: {
    USER: (state) => {
      return state.user;
    },
  },
  actions: {},
};
export default user;
