export function checkUser(vk_id) {
  // Формируем URL с GET-параметром
  const url = `https://vortexsignal.ru/app/api/checkUser.php?vk_id=${vk_id}`;

  // Выполняем fetch-запрос
  return fetch(url)
    .then((response) => {
      // Проверяем, успешен ли запрос
      if (!response.ok) {
        throw new Error(
          `Ошибка HTTP: ${response.status} ${response.statusText}`
        );
      }
      // Парсим ответ в JSON
      return response.json();
    })
    .then((data) => {
      //console.log("Данные получены:", data);
      return data; // Возвращаем данные
    })
    .catch((error) => {
      console.error("Ошибка при выполнении запроса:", error);
      throw error; // Пробрасываем ошибку дальшеd
    });
}
