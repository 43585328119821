const gameReady = {
  state: {
    allReady: {
      map: false,
      user: false,
      device: false,
      userCoords: false,
      all: false,
    },
  },
  mutations: {
    SET_MAP_READY_STATUS(state, val) {
      state.map = val;
    },
    SET_USER_READY_STATUS(state, val) {
      state.user = val;
    },
    SET_DEVICE_READY_STATUS(state, val) {
      state.device = val;
    },
    SET_USER_COORDS_READY_STATUS(state, val) {
      state.userCoords = val;
    },
    SET_ALL_READY_STATUS(state, val) {
      state.all = val;
    },
  },
  getters: {
    GAME_READY_STATUS: (state) => state.gameReady,
  },
  actions: {},
};
export default gameReady;
