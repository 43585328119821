<template>
  <div></div>
</template>

<script>
import { UAParser } from "ua-parser-js";
import { mapMutations } from "vuex";

export default {
  name: "DeviceDetector",
  data() {
    return {
      isMobile: false,
      isTablet: false,
      isDesktop: false,
      hz: false,
    };
  },
  mounted() {
    this.detectDevice();
  },
  methods: {
    ...mapMutations(["SET_DEVICE_TYPE"]),
    detectDevice() {
      const parser = new UAParser();
      const result = parser.getResult();

      if (result.device.type === "mobile") {
        this.SET_DEVICE_TYPE("mobile");
      } else if (result.device.type === "tablet") {
        this.SET_DEVICE_TYPE("tablet");
      } else {
        this.SET_DEVICE_TYPE("desktop");
      }
    },
  },
};
</script>
