const mapReady = {
  state: {
    mapReadyStatus: {
      ready: false,
      mapLoad: false,
      mapInit: false,
      msg: "Loading API YMaps",
    },
  },
  mutations: {
    SET_MAP_READY_STATUS(state, mapReadyStatus) {
      state.mapReadyStatus = mapReadyStatus;
    },
  },
  getters: {
    MAP_READY_STATUS: (state) => state.mapReadyStatus,
  },
  actions: {},
};
export default mapReady;
