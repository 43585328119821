<template>
  <div class="debugBlock">
    <!-- USER -->
    <h5
      @click="this.userBlock = !this.userBlock"
      style="text-decoration: underline"
    >
      Информация о пользователе
    </h5>
    <div v-show="this.userBlock" class="infoBlock">
      <img v-bind:src="this.USER.avatar" class="userAvatar" />
      <p>id: {{ this.USER.id }}</p>
      <p>name: {{ this.USER.name }}</p>
      <p>coords: {{ this.USER.coords }}</p>
    </div>
    <!-- DEVICE -->
    <h5
      @click="this.deviceBlock = !this.deviceBlock"
      style="text-decoration: underline"
    >
      Информация об устройстве
    </h5>
    <div v-show="this.deviceBlock" class="infoBlock">
      <p>type: {{ this.DEVICE.type }}</p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "DebugBlock",
  data() {
    return {
      userBlock: false,
      deviceBlock: false,
    };
  },
  components: {},
  computed: {
    ...mapGetters(["USER", "DEVICE"]),
  },
};
</script>

<style scoped>
.debugBlock {
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px;
  background-color: white;
  width: calc(100% - 20px);
}
.userAvatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #24292f;
}
.infoBlock {
  margin-top: 5px;
  padding: 15px;
  background: #eaeaea;
  border: 1px solid #24292f;
  border-radius: 5px;
}
</style>
