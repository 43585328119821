<script>
import bridge from "@vkontakte/vk-bridge";
import { mapMutations } from "vuex";
export default {
  name: "VKBridge",
  mounted() {
    this.connectBridge();
  },
  methods: {
    ...mapMutations(["SET_USER_NAME","SET_USER_VK_ID","SET_USER_VK_AVA"]),
    connectBridge() {
      bridge
        .send("VKWebAppInit")
        .then((data) => {
          if (data.result) {
            // Приложение инициализировано
            console.log(data.result);
            this.getBridgeinfo();
          } else {
            // Ошибка
          }
        })
        .catch((error) => {
          // Ошибка
          console.log(error);
        });
    },
    getBridgeinfo() {
      ///цепляем инфу про инициализации
      bridge
        .send("VKWebAppGetLaunchParams")
        .then((resp) => {
          if (resp.vk_app_id) {
            // Параметры запуска получены
            console.log(resp);
            this.getBridgeUserInfo(resp.vk_user_id);
          }
        })
        .catch((error) => {
          // Ошибка
          console.log(error);
        });
      // закончили цеплять
    },
    getBridgeUserInfo(vk_user_id) {
      bridge
        .send("VKWebAppGetUserInfo", {
          user_id: vk_user_id,
        })
        .then((data) => {
          if (data.id) {
            // Данные пользователя получены
            console.log(data);
            this.SET_USER_NAME(data.first_name);
            this.SET_USER_VK_ID(data.id);
            this.SET_USER_VK_AVA(data.photo_100);
          }
        })
        .catch((error) => {
          // Ошибка
          console.log(error);
        });
    },
  },
};
</script>
