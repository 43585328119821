import turf from "@/assets/libs/turf";

export default function updateUserPoint(userLocation) {
  let userFieldPoints = turf.circle(userLocation, 25, {
    units: "meters",
    steps: 100,
  });
  //console.log(userFieldPoints);
  userField.update({ geometry: userFieldPoints.geometry });
  userPoint.update({ coordinates: userLocation });
}
